import React, { useContext } from 'react'
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from '@material-ui/core'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import Controls from '../Controls/Controls'

import Dialog from './Dialog'
import { AuthContext } from '../../Context/AuthContext'

const ApproveTnCDialog = ({ open, close, isTutorFlow }) => {
  const classes = useStyles()

  const { AcceptTnC } = useContext(AuthContext)

  const handleVerify = async () => {
    await AcceptTnC()
    window.location.reload(false)
    close()
  }

  return (
    <Dialog open={open} fullWidth={false}>
      <DialogTitle>
        <div className={classes.header}>
          <p className="sub-text bold text-align-center">
            Terms & Conditions Updated!
          </p>
        </div>
      </DialogTitle>
      <Divider />
      <DialogContent classes={classes.root}>
        <div className={classes.content}>
          <p className={classes.text}>
            By accessing or using edvi&apos;s services, website, or mobile
            applications (the &quot;Service&quot;), you acknowledge that you
            have read, understood, and agree to be bound by our{' '}
            <a
              href={
                isTutorFlow
                  ? 'https://policies.edvi.app/tutor/terms'
                  : 'https://policies.edvi.app/terms'
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              {isTutorFlow
                ? 'Terms and Conditions and Privacy Policy.'
                : 'Terms and Conditions'}
            </a>
            {isTutorFlow ? '' : ' and '}
            {isTutorFlow ? (
              ''
            ) : (
              <a
                href="https://policies.edvi.app/privacy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy.
              </a>
            )}{' '}
            If you do not agree to these terms, you must refrain from using the
            Service.
          </p>
        </div>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Controls.Button onClick={handleVerify} style={{ margin: '0.5rem' }}>
          Accept Now
        </Controls.Button>
      </DialogActions>
    </Dialog>
  )
}
const useStyles = makeStyles(() =>
  createStyles({
    header: {
      display: 'flex',
      flexDirection: 'row',
      paddingLeft: 20,
      justifyContent: 'center',
      alignItems: 'center',
      marginInline: 40,
      '@media (max-width: 400px)': {
        paddingLeft: 0,
      },
    },
    root: {
      '& .MuiDialogContent-root': {
        '@media (max-width: 400px)': {
          padding: '8px 5px',
        },
      },
    },
    close: {
      position: 'absolute',
      right: 10,
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    text: {
      fontSize: '1rem',
      margin: '5px 0',
      textAlign: 'center',
    },
    bold: {
      fontWeight: 'bold',
    },
    otp: {
      padding: '20px 0',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      '@media (max-width: 400px)': {
        paddingBlock: 0,
      },
    },
    link: {
      textDecorationLine: 'underline',
      color: '#5687E1',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    inputStyle: {
      fontSize: '24px',
      margin: '0px 10px',
      padding: '0px',
    },
    otp_field: {
      margin: '20px 0px 0px 0px',
    },
  }),
)
export default ApproveTnCDialog
