import React from 'react'
import {
  FormControl,
  InputLabel,
  Select as MuiSelect,
  MenuItem,
  FormHelperText,
  ListSubheader,
  Checkbox,
} from '@material-ui/core'

export default function Select(props) {
  const {
    name,
    label,
    value,
    error = null,
    onChange,
    options,
    style,
    variant,
    children,
    labelStyle,
    multiple,
    manualSelectedItems,
    ...other
  } = props

  return (
    <FormControl
      variant={variant || 'outlined'}
      {...(error && { error: true })}
      style={style}
    >
      <InputLabel style={labelStyle}>{label}</InputLabel>
      <MuiSelect
        label={label}
        name={name}
        value={value}
        onChange={onChange}
        multiple={multiple}
        {...other}
      >
        {children}
        {[].concat(
          ...options.map((item) =>
            item.children !== undefined
              ? [
                  <ListSubheader style={{ lineHeight: 2, fontSize: 'medium' }}>
                    {item.title}
                  </ListSubheader>,
                ].concat(
                  ...item.children.map((child) =>
                    child.children !== undefined
                      ? [
                          <ListSubheader
                            style={{
                              marginLeft: '1rem',
                              fontSize: 'medium',
                              lineHeight: 1.2,
                              alignItems: 'center',
                              justifyItems: 'center',
                              display: 'flex',
                              flexDirection: 'row',
                            }}
                          >
                            {child.title}
                          </ListSubheader>,
                        ].concat(
                          ...child.children.map((subChild) => (
                            <MenuItem
                              style={{ marginLeft: '2rem' }}
                              key={subChild.id}
                              value={subChild.id}
                            >
                              <Checkbox
                                checked={
                                  manualSelectedItems.length > 0 &&
                                  manualSelectedItems.includes(child.id)
                                }
                              />
                              {subChild.title}
                            </MenuItem>
                          )),
                        )
                      : [
                          <MenuItem
                            key={child.id}
                            value={child.id}
                            style={{
                              marginLeft: '1rem',
                              background: manualSelectedItems.includes(child.id)
                                ? '#ebebeb'
                                : undefined,
                            }}
                          >
                            <Checkbox
                              checked={
                                manualSelectedItems.length > 0 &&
                                manualSelectedItems.includes(child.id)
                              }
                            />
                            {child.title}
                          </MenuItem>,
                        ],
                  ),
                )
              : [
                  <MenuItem key={item.id} value={item.id}>
                    {item.title}
                  </MenuItem>,
                ],
          ),
        )}
      </MuiSelect>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  )
}
