/* eslint-disable no-underscore-dangle */
import React, { createContext, useRef, useState, useEffect } from 'react'
import jwt from 'jsonwebtoken'
import { googleLogout } from '@react-oauth/google'
import { useSnackbar } from 'notistack'
import handleError from '../Global/HandleError/handleError'
import axiosGet from '../Global/Axios/axiosGet'
import showSuccessSnackbar from '../Components/Snackbar/successSnackbar'
import showErrorSnackbar from '../Components/Snackbar/errorSnackbar'
import axiosPost from '../Global/Axios/axiosPost'
import axiosPatch from '../Global/Axios/axiosPatch'

export const AuthContext = createContext()

const loginUrl = `/login/`
const registerUrl = `/register/`
const requestPasswordResetUrl = `/reset_password/request/`
const passwordResetUrl = `/reset_password/`
const setNewPasswordUrl = `/set_password/`
let tokenTimer

const AuthContextProvider = (props) => {
  const initialState = {
    role: '',
    name: '',
    email: '',
    user_id: '',
    avatar: '',
    is_email_verified: false,
    is_phone_verified: false,
    can_update_name: false,
    phone_number: null,
    terms_accepted_datetime: null,
  }
  const initialTokenState = {
    access_token: '',
    exp: '',
  }
  const initialFeatures = {
    img: '',
    msg: '',
  }
  const [features, setFeatures] = useState(initialFeatures)
  const tokenState = useRef(initialTokenState)
  const [authState, setAuthState] = useState(initialState)
  const [loading, setLoading] = useState(false)
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const [verifyEmailStatus, setVerifyEmailStatus] = useState(undefined)
  const [shouldShowHelp, setShouldShowHelp] = useState(false)

  useEffect(() => {
    Authenticate()
  }, [])

  const Refresh = async (update = false) => {
    const payload = {
      refresh: localStorage.getItem('refresh'),
    }
    try {
      const data = await axiosPost(`/login/refresh/`, { data: payload })
      const decodedToken = jwt.decode(data.data.access)
      let exp
      let user_id
      let role
      try {
        exp = decodedToken.exp
        user_id = decodedToken.user_id
        role = decodedToken.role
      } catch (err) {
        await LogOut()
        return
      }
      if (!exp || !user_id || !role) {
        await LogOut()
      }
      const expDate = new Date(parseInt(exp, 10) * 1000)
      localStorage.setItem('access', data.data.access)
      tokenState.current = {
        access_token: data.data.access,
        exp: expDate.toISOString(),
      }
      await __setAuthData(data.data.access, update)
    } catch (err) {
      await LogOut()
    }
  }

  const ChangePassword = async (oldPassword, newPassword) => {
    const payload = {
      password: oldPassword,
      new_password: newPassword,
    }
    return axiosPost(`/change_password/`, {
      data: payload,
      headers: getAuthHeader(),
    })
  }

  const NewPassword = async (values, token) => {
    try {
      const headers = {
        'Content-Type': 'application/json; charset=UTF-8',
      }
      const payload = {
        token,
        new_password: values.setNewPassword,
        batch: values.batch,
        bulk: values.bulk,
      }

      await axiosPost(setNewPasswordUrl, { data: payload, headers })

      showSuccessSnackbar(enqueueSnackbar, 'Account Created')
      return true
    } catch (err) {
      handleError(enqueueSnackbar, err)
    }
  }

  const LogIn = async (values) => {
    try {
      const headers = {
        'Content-Type': 'application/json; charset=UTF-8',
      }
      const data = await axiosPost(loginUrl, { data: values, headers })
      const { access, refresh } = data.data
      await __validateUser(access, refresh)
    } catch (err) {
      handleError(enqueueSnackbar, err)
    }
  }

  const Authenticate = async () => {
    setLoading(true)
    const authInformation = __getAuthData()
    if (!authInformation) {
      setLoading(false)
      return
    }
    const decodedToken = jwt.decode(authInformation.access)
    let exp
    let user_id
    let role
    try {
      exp = decodedToken.exp
      user_id = decodedToken.user_id
      role = decodedToken.role
    } catch (err) {
      await LogOut()
    }
    if (!exp || !user_id || !role) {
      return
    }
    const expDate = new Date(parseInt(exp, 10) * 1000)
    const now = new Date()
    const expiresIn = expDate.getTime() - now.getTime()

    if (expiresIn > 0) {
      await __setAuthData(authInformation.access, true)
    } else {
      await Refresh(true)
    }
  }

  const LogOut = async (data = {}) => {
    try {
      const refreshToken = {
        refresh: localStorage.getItem('refresh'),
      }
      window.$chatwoot.reset()
      if (localStorage.getItem('refresh')) {
        await axiosPost(`/logout/`, { data: { ...refreshToken, ...data } })
      }
    } finally {
      googleLogout()
      clearLocalStorageExceptPreferredLoginCountry()
      clearTimeout(tokenTimer)
      setVerifyEmailStatus(undefined)
      setIsLoggedIn(false)
      setAuthState(initialState)
      setLoading(false)
    }
  }

  const clearLocalStorageExceptPreferredLoginCountry = () => {
    const preferredLoginCountryCode = localStorage.getItem(
      'preferredLoginCountryCode',
    )
    localStorage.clear()
    if (preferredLoginCountryCode) {
      localStorage.setItem(
        'preferredLoginCountryCode',
        preferredLoginCountryCode,
      )
    }
  }

  const Register = async (values) => {
    try {
      const headers = {
        'Content-Type': 'application/json; charset=UTF-8',
      }
      const payload = {
        name: values.name,
        email: values.email,
        role: values.role,
        password: values.password,
      }
      const data = await axiosPost(registerUrl, { data: payload, headers })
      return data.status
    } catch (err) {
      handleError(enqueueSnackbar, err)
      return err?.response?.status || 400
    }
  }

  const RequestResetPassword = async (values) => {
    try {
      const headers = {
        'Content-Type': 'application/json; charset=UTF-8',
      }
      const payload = {
        email: values.email,
      }

      await axiosPost(requestPasswordResetUrl, {
        data: payload,
        headers,
      })

      showSuccessSnackbar(
        enqueueSnackbar,
        `Request sent on ${values.email} successfully! Please check your spam folder.`,
      )
    } catch (err) {
      handleError(enqueueSnackbar, err)
    }
  }

  const RequestResetPasswordOTP = async (values) => {
    try {
      const headers = {
        'Content-Type': 'application/json; charset=UTF-8',
      }
      const payload = {
        email: values.email,
      }

      const res = await axiosPost('/reset_password_otp/request/', {
        data: payload,
        headers,
      })
      if (res.status === 200) {
        showSuccessSnackbar(
          enqueueSnackbar,
          `OTP sent on ${values.email} successfully! Please check your spam folder.`,
        )
        return true
      }
      return false
    } catch (err) {
      handleError(enqueueSnackbar, err)
    }
  }

  const ResetPassword = async (values, token) => {
    try {
      const headers = {
        'Content-Type': 'application/json; charset=UTF-8',
      }
      const payload = {
        token,
        new_password: values.setNewPassword,
      }
      await axiosPost(passwordResetUrl, { data: payload, headers })

      showSuccessSnackbar(
        enqueueSnackbar,
        'Password has been changed successfully',
      )
      return true
    } catch (error) {
      handleError(enqueueSnackbar, error)
    }
  }

  const ResetPasswordOTP = async (values) => {
    try {
      const headers = {
        'Content-Type': 'application/json; charset=UTF-8',
      }
      const payload = {
        email: values.email,
        otp: values.otp,
        password: values.setNewPassword,
      }
      await axiosPost('/reset_password_otp/', { data: payload, headers })

      showSuccessSnackbar(
        enqueueSnackbar,
        'Password has been changed successfully',
      )
      return true
    } catch (error) {
      handleError(enqueueSnackbar, error)
    }
  }

  const SetProfile = (data) => {
    const {
      is_email_verified,
      name,
      email,
      is_phone_verified,
      phone_number,
      avatar,
      can_update_name,
      teacher,
      terms_accepted_datetime,
    } = data.data
    setAuthState((state) => ({
      ...state,
      is_email_verified,
      name,
      email,
      is_phone_verified,
      phone_number,
      avatar,
      can_update_name,
      teacher,
      terms_accepted_datetime,
    }))
    localStorage.setItem('name', name)
    localStorage.setItem('email', email)
  }

  const GetProfile = async () => {
    try {
      const data = await axiosGet(`/user_profile/`, {
        headers: getAuthHeader(),
      })
      const userProfile = data.data
      if (
        userProfile.role === 'T' ||
        (userProfile.role === 'S' && userProfile.consumer)
      ) {
        window.$chatwoot.setUser(data?.data?.id, {
          email: data?.data?.email,
          name: data?.data?.name,
          avatar_url: data?.data?.avatar,
          phone_number: data?.data?.phone_number,
        })
        SetProfile(data)
      } else {
        await LogOut()
        handleError(enqueueSnackbar, {
          response: {
            data: {
              code: 'err_invalid_user_login',
            },
          },
        })
      }
    } catch (err) {
      showErrorSnackbar(
        enqueueSnackbar,
        err.response.data.detail || 'Unknown Error Occured',
      )
    }
  }

  const UpdateProfile = async (data) => {
    try {
      const res = await axiosPatch(`/user_profile/${authState.user_id}/`, {
        data,
        headers: getAuthHeader(),
      })
      return res
    } catch (err) {
      // showErrorSnackbar(enqueueSnackbar, err)
      return {
        success: false,
        err,
      }
    }
  }

  const VerifyEmail = async (registerToken) => {
    try {
      const tokenPayload = {
        token: registerToken,
      }
      const data = await axiosPost(`/verify_email/`, { data: tokenPayload })
      setVerifyEmailStatus({ data: data.request.status })
      if (data.request.status === 200) {
        setAuthState((state) => ({ ...state, is_email_verified: true }))
      }
      return {
        data: data.request.status,
      }
    } catch (err) {
      setVerifyEmailStatus({ data: err.response.status })
      return {
        data: err.response.status,
      }
    }
  }

  const VerifyPhoneOTP = async (data) => {
    try {
      const payload = {
        contact: 'P',
        otp: data,
      }
      const res = await axiosPost(`/verify_otp/`, {
        data: payload,
        headers: getAuthHeader(),
      })

      if (res.request.status === 200) {
        setAuthState((state) => ({
          ...state,
          is_phone_verified: true,
        }))
        return {
          success: true,
        }
      }
    } catch (err) {
      handleError(enqueueSnackbar, err)
      return {
        success: false,
      }
    }
  }

  const RequestRegistrationOTP = async (number) => {
    try {
      const payload = {
        phone_number: `91${number}`,
      }
      const res = await axiosPost('/register/request_phone_otp/', {
        data: payload,
      })
      if (res.status === 200) {
        showSuccessSnackbar(enqueueSnackbar, 'OTP has been sent successfully!')
        return true
      }
      return false
    } catch (err) {
      handleError(enqueueSnackbar, err)
      return false
    }
  }

  const RegisterWithPhone = async (data) => {
    try {
      const res = await axiosPost('/register/', {
        data,
      })
      if (res.status === 201) {
        showSuccessSnackbar(enqueueSnackbar, 'Your account has been created')
        return true
      }
      showErrorSnackbar(enqueueSnackbar, 'Error creating your account')
      return false
    } catch (err) {
      handleError(enqueueSnackbar, err)
      return false
    }
  }

  const RequestLoginOTP = async (number) => {
    try {
      const payload = {
        phone_number: number,
      }
      const res = await axiosPost('/login/request_phone_otp/', {
        data: payload,
      })
      if (res.status === 200) {
        showSuccessSnackbar(enqueueSnackbar, 'OTP has been sent successfully!')
        return true
      }
      return false
    } catch (err) {
      handleError(enqueueSnackbar, err)
      return false
    }
  }

  const LoginWithPhone = async (data) => {
    try {
      const res = await axiosPost('/login/', {
        data,
      })
      const { access, refresh } = res.data
      await __validateUser(access, refresh)
    } catch (err) {
      handleError(enqueueSnackbar, err)
    }
  }

  const __validateUser = async (access, refresh) => {
    const userProfileResponse = await axiosGet(`/user_profile/`, {
      headers: {
        Authorization: `Bearer ${access}`,
      },
    })
    const userProfile = userProfileResponse.data
    if (
      userProfile.role === 'T' ||
      (userProfile.role === 'S' && userProfile.consumer)
    ) {
      localStorage.setItem('refresh', refresh)
      await __setAuthData(access, true)
    } else {
      handleError(enqueueSnackbar, {
        response: {
          data: {
            code: 'err_invalid_user_login',
          },
        },
      })
    }
  }

  const __getAuthData = () => {
    let access = localStorage.getItem('access')
    if (!access) {
      Refresh(true)
    }
    access = localStorage.getItem('access')
    const role = localStorage.getItem('role')
    const name = localStorage.getItem('name')
    const email = localStorage.getItem('email')
    if (!access) {
      return
    }
    return {
      access,
      role,
      name,
      email,
    }
  }

  const __setAuthData = async (token, setState) => {
    const decodedToken = jwt.decode(token)
    let exp
    let user_id
    let role
    try {
      exp = decodedToken.exp
      user_id = decodedToken.user_id
      role = decodedToken.role
    } catch (err) {
      return
    }
    if (!exp || !user_id || !role) {
      await LogOut()
    }
    localStorage.setItem('access', token)
    const expDate = new Date(parseInt(exp, 10) * 1000)
    tokenState.current = {
      access_token: token,
      exp: expDate.toISOString(),
    }
    setIsLoggedIn(true)
    if (setState) {
      setAuthState((state) => ({
        ...state,
        role,
        user_id,
      }))
      await GetProfile()
    }
    setLoading(false)
  }

  const getAuthHeader = () => ({
    Authorization: `Bearer ${
      tokenState.access_token || localStorage.getItem('access')
    }`,
    'E-Platform': `classroom`,
  })

  const SetFeatures = (img, msg) => {
    setFeatures({
      ...features,
      img,
      msg,
    })
  }

  const DirectGoogleLogin = async (payload) => {
    try {
      setLoading(true)
      const headers = {
        'Content-Type': 'application/json; charset=UTF-8',
      }
      const data = await axiosPost(`/login/google/`, { data: payload, headers })
      const { access, refresh } = data.data
      localStorage.setItem('refresh', refresh)
      __setAuthData(access, true)
    } catch (err) {
      setLoading(false)
      handleError(enqueueSnackbar, err)
    }
  }

  const DirectGoogleRegister = async (payload) => {
    try {
      const data = {
        idm_token: payload.id_token,
      }

      const headers = {
        'Content-Type': 'application/json; charset=UTF-8',
      }

      const loginRes = await axiosPost(`/login/google/`, {
        data,
        headers,
      })

      if (loginRes.status === 200) {
        const { access, refresh } = loginRes.data
        localStorage.setItem('refresh', refresh)
        __setAuthData(access, true)
      }
    } catch (err) {
      const headers = {
        'Content-Type': 'application/json; charset=UTF-8',
      }

      if (err.response.data.code === 'no_active_account') {
        const registerRes = await axiosPost(`/register/google/`, {
          data: payload,
          headers,
        })

        return registerRes.status
      }
    }
  }

  const UploadProfilePhoto = async (formData, filetype, file_format) => {
    try {
      // Get Signed URL
      const storageUrlRes = await axiosGet(
        `/get_storage_url/?filetype=${filetype}&file_format=${file_format}`,
        {
          headers: getAuthHeader(),
        },
      )
      const storageUrl = storageUrlRes.data.url
      const storage_path = storageUrl.split('?')[0]

      // Upload File on Google Cloud Storage
      await axiosPost(storageUrl, {
        data: formData,
      })

      // Send data to API
      const res = await UpdateProfile({ avatar: storage_path })
      if (res.success)
        showSuccessSnackbar(enqueueSnackbar, 'Profile Photo Updated')
      return {
        success: true,
      }
    } catch (err) {
      handleError(enqueueSnackbar, err)
      return {
        success: false,
      }
    }
  }

  const AcceptTnC = async () => {
    try {
      const res = await axiosPost(`/users/accept-terms-conditions/`, {
        headers: getAuthHeader(),
      })
      return res
    } catch (err) {
      return {
        success: false,
        err,
      }
    }
  }

  return (
    <AuthContext.Provider
      value={{
        LogIn,
        authState,
        Register,
        RequestResetPassword,
        ChangePassword,
        loading,
        isLoggedIn,
        setIsLoggedIn,
        Refresh,
        LogOut,
        getAuthHeader,
        ResetPassword,
        GetProfile,
        UpdateProfile,
        VerifyEmail,
        VerifyPhoneOTP,
        verifyEmailStatus,
        DirectGoogleLogin,
        DirectGoogleRegister,
        features,
        SetFeatures,
        UploadProfilePhoto,
        NewPassword,
        RequestRegistrationOTP,
        RegisterWithPhone,
        RequestLoginOTP,
        LoginWithPhone,
        RequestResetPasswordOTP,
        ResetPasswordOTP,
        shouldShowHelp,
        setShouldShowHelp,
        AcceptTnC,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  )
}

export default AuthContextProvider
